import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import LandingHeader from '../LandingPage/LandingHeader';
import Footer from '../Footer/Footer';
import ReCAPTCHA from 'react-google-recaptcha';
import { FaEnvelope, FaLock, FaPhone, FaUser } from 'react-icons/fa';

const SignupForm = () => {
  const [formData, setFormData] = useState({
    uname: '',
    email: '',
    password: '',
    confirmPassword: '',
    mobileNumber: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const Navigate = useNavigate()


  const handleSubmit = async (e) => {
    e.preventDefault();

    const { uname, email, password, confirmPassword, mobileNumber } = formData;

    if (password !== confirmPassword) {
      alert("Passwords don't match!");
      return;
    }

    try {
      const newUser = {
        username: uname,
        email,
        password,
        mobile: mobileNumber
      };

      // Make POST request to backend endpoint
      const response = await axios.post('https://sjpapi.prettywomen.in/API/v1/signup', newUser);

      // Check response status and handle accordingly
      if (response.status === 200) {
        console.log(response.data)
        alert(response.data.message); // Assuming backend sends back { message: 'User registered successfully...' }
        Navigate('/otp-verification')
        // Reset form data after successful registration
        setFormData({
          uname: '',
          email: '',
          password: '',
          confirmPassword: '',
          mobileNumber: ''
        });
      } else {
        alert('Failed to register user. Please try again.'); // Handle non-200 status codes
      }
    } catch (error) {
      // Handle error scenarios
      if (error.response) {
        console.error('Response error:', error.response); // Log detailed response error
        alert(error.response.data.error); // Display backend error message if available
      } else if (error.request) {
        console.error('Request error:', error.request); // Log request error
        alert('Failed to send request to server.'); // Notify user about request failure
      } else {
        console.error('Error:', error.message); // Log other errors
        alert('Something went wrong. Please try again.'); // Generic error message
      }
    }
  };

  console.log(formData)
  return (
    <>
      <LandingHeader />    
      {/* <Container className="d-flex justify-content-center align-items-center" style={{ fontFamily: 'Arial, sans-serif' }}>
        <Row className="justify-content-center">
          <Col className="p-4">
            <Form onSubmit={handleSubmit}>
              <h3 className="text-center mb-4">Create account</h3>
              <div className='mb-3'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FaUser style={{ marginRight: '10px' }} />
                  <input
                    type='text'
                    name='uname'
                    placeholder='User Name'
                    style={{ border: '1px solid black', padding: '10px 100px', flex: 1 }}
                    value={formData.uname}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className='mb-3'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FaEnvelope style={{ marginRight: '10px' }} />
                  <input
                    type='text'
                    name='email'
                    placeholder='Email'
                    style={{ border: '1px solid black', padding: '10px 100px', flex: 1 }}
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className='mb-3'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FaPhone style={{ marginRight: '10px' }} />
                  <input
                    type='number'
                    name='mobileNumber'
                    placeholder='Mobile Number'
                    style={{ border: '1px solid black', padding: '10px 100px', flex: 1 }}
                    value={formData.mobileNumber}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className='mb-3'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FaLock style={{ marginRight: '10px' }} />
                  <input
                    type='password'
                    name='password'
                    placeholder='Enter Password'
                    style={{ border: '1px solid black', padding: '10px 100px', flex: 1 }}
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className='mb-3'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FaLock style={{ marginRight: '10px' }} />
                  <input
                    type='password'
                    name='confirmPassword'
                    placeholder='Confirm Password'
                    style={{ border: '1px solid black', padding: '10px 100px', flex: 1 }}
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <ReCAPTCHA className='mb-3'
                sitekey="6LcL4CIqAAAAAHgyMy5VaZzK-RT5gj13587tI1ZU"
              />
              <div className="d-grid gap-2 text-center">
                <Button variant="dark" type="submit">Create</Button>
              </div>
              <p className="text-center mt-3">
                Already Registered?
                <Link to="/login" className="ms-3" style={{ textDecoration: 'none' }}>
                  <Button variant="dark" size="sm">Login</Button>
                </Link>
              </p>
            </Form>
          </Col>
        </Row>
      </Container> */}
      <Container className="d-flex justify-content-center align-items-center" style={{ fontFamily: 'Arial, sans-serif' }}>
        <Row className="justify-content-center">
          <Col  className="p-4">
            <Form onSubmit={handleSubmit}>
              <h3 className="text-center mb-4">CREATE ACCOUNT</h3>

              {[
                { icon: <FaUser />, name: 'uname', placeholder: 'User Name', type: 'text' },
                { icon: <FaEnvelope />, name: 'email', placeholder: 'Email', type: 'email' },
                { icon: <FaPhone />, name: 'mobileNumber', placeholder: 'Mobile Number', type: 'tel' },
                { icon: <FaLock />, name: 'password', placeholder: 'Enter Password', type: 'password' },
                { icon: <FaLock />, name: 'confirmPassword', placeholder: 'Confirm Password', type: 'password' },
              ].map(({ icon, name, placeholder, type }) => (
                <div className='mb-3' key={name}>
                  <div className='input-group'>
                    <span className='input-group-text'>{icon}</span>
                    <input
                      type={type}
                      name={name}
                      placeholder={placeholder}
                      className='form-control'
                      value={formData[name]}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
              ))}

              <div className='mb-3'>
                <ReCAPTCHA sitekey="6LcL4CIqAAAAAHgyMy5VaZzK-RT5gj13587tI1ZU" />
              </div>

              <div className="d-grid gap-2 text-center">
                <Button variant="dark" type="submit">Create</Button>
              </div>

              <p className="text-center mt-3">
                Already Registered?
                <Link to="/login" className="ms-3" style={{ textDecoration: 'none' }}>
                  <Button variant="dark" size="sm">Login</Button>
                </Link>
              </p>
            </Form>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default SignupForm;


