
// import React, { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import LandingHeader from '../LandingPage/LandingHeader';
// import Footer from '../Footer/Footer';
// import ReCAPTCHA from 'react-google-recaptcha';

// const Login = () => {
//   const navigate = useNavigate();
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [id, setId] = useState('');

//   const handleEmailChange = (e) => setEmail(e.target.value);
//   const handlePasswordChange = (e) => setPassword(e.target.value);
//  const handleUserIdChange = (e) => setId(e.target.value);

//   const handleLogin = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await fetch('https://sjpapi.prettywomen.in/API/v1/login', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email, password, id }),
//       });

//       const data = await response.json();
//       console.log(data.user)

//       if (response.ok) {
//         localStorage.setItem('userData', JSON.stringify({ email, password, id: data.user.id }));
//         localStorage.setItem('emailUser',email)
//          localStorage.setItem('userId', data.user.id);

//         alert('Login successful!');
//         navigate('/');
//       } else {
//         alert(data.error || 'Invalid credentials. Please try again.');
//       }
//     } catch (error) {
//       console.error('Login error:', error);
//       alert('Login failed. Please try again later.');
//     }
//   };




//   return (
//     <>
//       <LandingHeader />
//       <div className='d-flex justify-content-center' style={{ fontFamily: 'Arial, sans-serif' }}>
//         <div className='col-lg-4'>
//           <div className='p-4'>
//             <form onSubmit={handleLogin}>
//               <h3 className='text-center mb-5'><strong>LOGIN</strong></h3>
//               <div className='mb-3'>
//                 <input
//                   type='email'
//                   placeholder='Email'
//                   style={{ border: '1px solid black', padding: '10px 90px' }}
//                   value={email}
//                   onChange={handleEmailChange}
//                   required
//                 />
//               </div>
//               <div className='mb-3'>
//                 <input
//                   type='password'
//                   placeholder='Password'
//                   style={{ border: '1px solid black', padding: '10px 90px' }}
//                   value={password}
//                   onChange={handlePasswordChange}
//                   required
//                 />
//               </div>

//               <ReCAPTCHA className='mb-3'
//               sitekey="6LcL4CIqAAAAAHgyMy5VaZzK-RT5gj13587tI1ZU"             
//            />
//               <Link to='/forgotpassword' className='ms-1' style={{ textDecoration: 'none' }}>
//                 Forgot Password?
//               </Link>

//               <Link to='/guestlogin' className='ms-3'>
//                 <a className='text-dark' style={{ borderRadius: '5px' }}>
//                   Guest Login
//                 </a>
//               </Link>
//               <Link to='/signup' className='ms-3'>
//                 <a className='text-dark' style={{ borderRadius: '5px' }}>
//                   Create account
//                 </a>
//               </Link>

//               <div className='gap-2 mt-2'>
//                 <button type='submit' className='btn btn-dark'style={{width:'83%'}}>
//                   LOGIN
//                 </button>
//               </div>
//             </form>

//              {/* <p className='text-center mt-3'>
//               <GoogleLogin
//                 onSuccess={handleGoogleLoginSuccess}
//                 onError={error => console.error('Google login error:', error)}
//                 buttonText='Continue with Google'
//                 className='btn btn-outline-dark me-2'
//               />
//             </p>
//             <p className='text-center mt-3'>
//               <FacebookLogin
//                 appId="1003390944866975"
//                 autoLoad={false}
//                 fields="name,email,picture"
//                 callback={handleFacebookLoginSuccess}
//                 onError={handleFacebookLoginError}
//                 textButton="Continue with Facebook"
//                 cssClass="btn mb-3 btn-outline-dark"
//               />
//             </p>  */}
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Login;




import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingHeader from '../LandingPage/LandingHeader';
import Footer from '../Footer/Footer';
import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleLogin } from '@react-oauth/google';
import { AiOutlineLock, AiOutlineLogin, AiOutlineMail, AiOutlineUser } from 'react-icons/ai';
import FacebookLogin from 'react-facebook-login';
import axios from 'axios'

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://sjpapi.prettywomen.in/API/v1/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('userData', JSON.stringify({ email, id: data.user.id }));
        localStorage.setItem('userId', data.user.id);
        localStorage.setItem('emailUser', email);
        alert('Login successful!');
        navigate('/');
      } else {
        alert(data.error || 'Invalid credentials. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('Login failed. Please try again later.');
    }
  };

  const handleGoogleLogin = async (credentialResponse) => {
    const token = credentialResponse.credential;
    try {
      const response = await fetch('https://sjpapi.prettywomen.in/API/v1/auth/google', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      console.log(credentialResponse)

      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('userId', data.user.id);
        localStorage.setItem('emailUser',data.user.email);
        localStorage.setItem('userData', JSON.stringify({ email: data.user.email, id: data.user.id }));
        alert('Google login successful!');
        navigate('/');
      } else {
        alert(data.error || 'Google login failed. Please try again.');
      }
    } catch (error) {
      console.error('Google login error:', error);
      alert('Google login failed. Please try again later.');
    }
  };

  const handleFacebookLoginSuccess = (response) => {
    console.log(response);
  }


  return (
    <>
      <LandingHeader />
      <div className='d-flex justify-content-center' style={{ fontFamily: 'Arial, sans-serif' }}>
        <div className='col-lg-4 col-md-6 col-sm-8'>
          <div className='p-4'>
            <form onSubmit={handleLogin}>
              <h3 className='text-center mb-5'><strong>LOGIN</strong></h3>
              <div className='mb-3' style={{ position: 'relative' }}>
                <AiOutlineMail style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }} />
                <input
                  type='email'
                  placeholder='Email'
                  style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%' }}
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </div>
              <div className='mb-3' style={{ position: 'relative' }}>
                <AiOutlineLock style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }} />
                <input
                  type='password'
                  placeholder='Password'
                  style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%' }}
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
              </div>
              <ReCAPTCHA className='mb-3' sitekey="6LcL4CIqAAAAAHgyMy5VaZzK-RT5gj13587tI1ZU" />
              <div className='d-flex justify-content-between mb-3'>
                <Link to='/forgotpassword' style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                  <AiOutlineLock style={{ marginRight: '5px' }} />
                  Forgot Password?
                </Link>
                <Link to='/guestlogin' style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                  <AiOutlineLogin style={{ marginRight: '5px' }} />
                  Guest Login
                </Link>
                <Link to='/signup' style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                  <AiOutlineUser style={{ marginRight: '5px' }} />
                  Create account
                </Link>
              </div>
              <div className='gap-2'>
                <button type='submit' className='btn btn-dark' style={{ width: '100%' }}>LOGIN</button>
              </div>
            </form>
            <div className='mt-3'>
              <GoogleLogin
                onSuccess={handleGoogleLogin}
                onError={() => console.log('Login Failed')}
                style={{ width: '100%' }}
              />

              <p className='text-center mt-3'>
                {/* <FacebookLogin
                  appId="1003390944866975"
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={handleFacebookLoginSuccess}
                  // onError={handleFacebookLoginError}
                  textButton="Continue with Facebook"
                  cssClass="btn mb-3 btn-outline-dark"
                /> */}
              </p>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
