

// import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
// import axios from 'axios';
// import CartItem from './cartItem';
// import Footer from '../Footer/Footer';
// import { Link } from 'react-router-dom';
// import LandingHeader from '../LandingPage/LandingHeader';

// const CartTab = () => {
//   const [cartItems, setCartItems] = useState([]);
//   const [alert, setAlert] = useState({ message: '', type: '' });
//   const dispatch = useDispatch();

//   const userId = localStorage.getItem('userId');

//   const fetchCartItems = async () => {
//     try {
//       const response = await axios.post('https://sjpapi.prettywomen.in/API/v1/api/cart', {
//         userId  // Send userId in the request body
//       });
//       setCartItems(response.data);
//       localStorage.setItem('cartlength', response.data.length);
//     } catch (err) {
//       setAlert({ message: 'Failed to fetch cart items. Please try again later.', type: 'error' });
//       console.error('Failed to fetch cart items:', err);
//     }
//   };

//   useEffect(() => {
//     fetchCartItems();
//   }, []);

//   const handleRemoveItem = (id) => {
//     setCartItems((prevItems) => prevItems.filter(item => item.id !== id));
//   };

//   return (
//     <div>
//       <LandingHeader />
//       <div className="container mt-5">
//         <h1 className='text-center mt-5' style={{ color: '#d47bb1' }}><strong>SHOPPING CART</strong></h1>
//         <div className='container-fluid mt-5'>
//           <div className='row bg-light justify-content-center'>
//             {cartItems.length > 0 ? (
//               cartItems.map((item) => (
//                 <CartItem key={item.id} data={item} onRemove={handleRemoveItem} />
//               ))
//             ) : (
//               <div className='text-center'>Your cart is empty</div>
//             )}
//           </div>
//         </div>
//         {/* <div className='container grid grid-cols-2 text-center mt-5'>
//           <Link to="/checkoutpage">
//             <button className='btn btn-dark btn-sm mb-2' style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}>CHECKOUT</button>
//           </Link>
//         </div> */}
//         <div className='container grid grid-cols-2 text-center mt-5'>
//           {cartItems.length > 0 ? (
//             <Link to="/checkoutpage">
//               <button className='btn btn-dark btn-sm mb-2' style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}>
//                 CHECKOUT
//               </button>
//             </Link>
//           ) : (
//             <button className='btn btn-dark btn-sm mb-2' style={{ border: '1px solid darkgrey', padding: '15px', width: '100%', opacity: 0.5, cursor: 'not-allowed' }} disabled>
//               CHECKOUT
//             </button>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default CartTab;

// Your existing imports
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import CartItem from './cartItem';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import LandingHeader from '../LandingPage/LandingHeader';

const CartTab = () => {
  const [cartItems, setCartItems] = useState([]);
  const [alert, setAlert] = useState({ message: '', type: '' });
  const dispatch = useDispatch();
  const userId = localStorage.getItem('userId');

  const fetchCartItems = async () => {
    try {
      const response = await axios.post('https://sjpapi.prettywomen.in/API/v1/api/cart', { userId });
      setCartItems(response.data);
      localStorage.setItem('cartlength', response.data.length);
    } catch (err) {
      setAlert({ message: 'Failed to fetch cart items. Please try again later.', type: 'error' });
      console.error('Failed to fetch cart items:', err);
    }
  };

  useEffect(() => {
    fetchCartItems();
  }, []);

  const handleRemoveItem = (id) => {
    setCartItems((prevItems) => prevItems.filter(item => item.id !== id));
  };

  const totalPrice = cartItems.reduce((total, item) => {
    return total + (item.pprice - (item.pprice * item.discount) / 100) * item.quantity;
  }, 0);

  return (
    <div>
      <LandingHeader />
      <div className="container mt-5">
        <h1 className='text-center mt-5' style={{ color: '#d47bb1' }}><strong>SHOPPING CART</strong></h1>
        <div className='container-fluid mt-5'>
          <div className='row bg-light justify-content-center'>
            {cartItems.length > 0 ? (
              cartItems.map((item) => (
                <CartItem key={item.id} data={item} onRemove={handleRemoveItem} />
              ))
            ) : (
              <div className='text-center'>Your cart is empty</div>
            )}
          </div>
        </div>
        <div className='container text-center mt-5'>
          <h6>Total Price: Rs. {Math.round(totalPrice)}</h6>
          {cartItems.length > 0 ? (
            <Link to="/checkoutpage">
              <button className='btn btn-dark btn-sm mb-2' style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}>
                CHECKOUT
              </button>
            </Link>
          ) : (
            <button className='btn btn-dark btn-sm mb-2' style={{ border: '1px solid darkgrey', padding: '15px', width: '100%', opacity: 0.5, cursor: 'not-allowed' }} disabled>
              CHECKOUT
            </button>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CartTab;
