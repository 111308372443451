
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import Footer from '../Footer/Footer';
import { FaRupeeSign } from 'react-icons/fa';
import { addToCart } from '../../Redux/cartActions';
import { removeFromWishlist } from '../../Redux/wishlistActions';
import LandingHeader from '../LandingPage/LandingHeader';

const Wishlist = () => {
  const wishlistItems = useSelector(state => state.wishlist.wishlistItems);
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
  }, [wishlistItems]);

  const handleAddToCart = async (item) => {
    setIsUpdating(true);
    setError(null);

    try {
      // Make API call to add item to cart
      const response = await axios.post('https://sjpapi.prettywomen.in/API/v1/api/add-to-cart', {
        productId: item.productId,
        quantity: 1 // Default quantity
      });

      if (response.status === 200) {
        // Dispatch actions if API call is successful
        dispatch(addToCart(item));
        dispatch(removeFromWishlist(item.productId));
        alert('Product added to cart');
      } else {
        // Handle non-200 status codes
        setError(response.data.error || 'Out Off Stock');
      }
    } catch (err) {
      // Handle API call errors
      console.error('There was an error adding the product to the cart!', err);
      setError('Out Off Stock');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDeleteFromWishlist = (productId) => {
    dispatch(removeFromWishlist(productId));
  };

  return (
    <div>
      <LandingHeader />
      <div style={{fontFamily: 'Arial, sans-serif', fontSize: '12px'}}>
        <h1 className='text-center mt-5'style={{color:' #d47bb1'}}><strong>WISHLIST</strong></h1>
        {wishlistItems.length === 0 ? (
          <p>Your wishlist is empty.</p>
        ) : (
          <div className="container mt-5">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className="table-dark">
                  <tr>
                    <th>Image</th>
                    <th>Name</th>                    
                    <th>Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {wishlistItems.map(item => (
                    <tr key={item.productId}>
                      <td>
                        <img 
                          src={item.image} 
                          alt={item.name} 
                          className="img-fluid" 
                          style={{ maxWidth: '100px', maxHeight: '100px' }} 
                        />
                      </td>
                      <td>{item.name}</td>                    
                      <td>
                        {item.discount ? (
                          <>
                            <span style={{ color: 'green', fontWeight: 'bold' }}>
                              <FaRupeeSign /> {Math.round(item.price - (item.price * item.discount) / 100)}
                            </span>{' '}
                            <span style={{ textDecoration: 'line-through', color: '#888', fontSize: '0.85rem', marginLeft: '5px' }}>
                              <FaRupeeSign /> {item.price}
                            </span>{' '}
                            <span style={{ color: '#d1235b', fontSize: '0.85rem', marginLeft: '5px' }}>
                              ({item.discount}% Off)
                            </span>
                          </>
                        ) : (
                          <span style={{ color: '#d1235b', fontWeight: 'bold' }}>
                            <FaRupeeSign /> {item.price}
                          </span>
                        )}
                      </td>
                      <td>
                        <button 
                          onClick={() => handleAddToCart(item)} 
                          className="btn btn-primary btn-sm me-2"  style={{padding: '1px 10px'}}
                          disabled={isUpdating}
                        >
                          {isUpdating ? 'Adding...' : 'Add to Cart'}
                        </button>
                        <button 
                          onClick={() => handleDeleteFromWishlist(item.productId)} 
                          className="btn btn-dark btn-sm"  style={{padding: '1px 10px'}}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {error && <p className='text-danger'>{error}</p>}
      </div>
      <Footer />
    </div>
  );
};

export default Wishlist;
